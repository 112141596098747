import { call, put } from 'redux-saga/effects';
import {
    HIDE_SPINNER,
    SHOW_SPINNER,
} from '../../redux/modules/spinner/spinner';
import { retriable } from '../retriable';
import {
    FETCH_APP_CONFIG_FAIL,
    FETCH_APP_CONFIG_SUCCESS,
} from '../../redux/modules/appConfiguration/appConfiguration-actions';
import {
    COMMON_APPLICATION_CONFIG_URL,
    GLOBAL_PROPERTIES,
} from '../../constants/constants';
import API from '../../constants/api';
import {
    getJsonPath,
    isArrayValidAndNotEmpty,
    isJsonString,
} from '../../constants/CommonUtil';
import {
    dynamicProperties,
    persistAnalyticsCurrencyNumericSystem,
    persistBillRoundPlaces,
    persistRoundingMethodology,
    persistRoundingPlaces,
} from '../../constants/state';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export function* fetchAppConfigWorkerSaga(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        console.log('searching', response.data);
        const requiredGPs = [
            GLOBAL_PROPERTIES.SECONDARY_IDENTIFIER_LABEL,
            GLOBAL_PROPERTIES.EP_STRICT_CHECK,
            GLOBAL_PROPERTIES.ROUNDING_PLACES,
            GLOBAL_PROPERTIES.CURRENCY,
            GLOBAL_PROPERTIES.IGNORE_BATCH_PRICE,
            GLOBAL_PROPERTIES.MULTIPLE_TOKEN_TYPE_SUPPORTED,
            GLOBAL_PROPERTIES.SHOW_MY_PAYSLIP_IN_DASHBOARD,
            GLOBAL_PROPERTIES.PACKAGE_SPECIAL_CASE_CONFIG,
            GLOBAL_PROPERTIES.TAX_EXEMPTION_ATTRIBUTES,
            GLOBAL_PROPERTIES.BILL_AMOUNT_ROUND,
            GLOBAL_PROPERTIES.NON_EDITABLE_NON_DRUGS,
            GLOBAL_PROPERTIES.INSURANCE_DIRECT_BILLABLE_CATEGORY,
            GLOBAL_PROPERTIES.ENCOUNTER_OT_STATUS_BIT_POS_MAP,
            GLOBAL_PROPERTIES.INS_REG_LOAD_SCHEME,
            GLOBAL_PROPERTIES.ALLOW_CONTRACT_EDIT_FOR_PAST_DATES,
            GLOBAL_PROPERTIES.SHOW_CAF_LINES_IN_CLAIM_DETAILS,
            GLOBAL_PROPERTIES.ENABLE_GENERIC_DRUG_PRESCRIPTION,
            GLOBAL_PROPERTIES.ENABLE_PRINTING_PRODUCT_DEPENDENT_DOCUMENTS,
            GLOBAL_PROPERTIES.ENABLE_PRINTING_PAYER_INVOICE_IN_REGISTRATION,
            GLOBAL_PROPERTIES.ENABLE_DAILY_COPAY_LIMIT,
            GLOBAL_PROPERTIES.NUACARE_MAX_PROVIDER_FAVORITES,
            GLOBAL_PROPERTIES.ENABLE_PRINT_SIDEMENU_REGISTRATION,
            GLOBAL_PROPERTIES.REMOVE_REPORT_ON_EDIT_STUDY,
            GLOBAL_PROPERTIES.ENABLE_COST_CHECK,
            GLOBAL_PROPERTIES.COST_CHECK_PAYERS,
            GLOBAL_PROPERTIES.DAILY_MAX_COPAY_MANDATORY,
            GLOBAL_PROPERTIES.MAX_COPAY_MANDATORY,
            GLOBAL_PROPERTIES.ENABLE_A4_INVOICE_PRINT,
            GLOBAL_PROPERTIES.PRESCRIPTION_TYPES_FOR_PAYER_TYPES,
            GLOBAL_PROPERTIES.NUACARE_NON_EDITABLE_NON_DRUGS,
            GLOBAL_PROPERTIES.DISABLE_ID_CARD_MANDATORY_FOR_INSURANCE_PATIENT,
            GLOBAL_PROPERTIES.NUMBER_OF_DAYS_FOR_PREVIOUS_PURCHASE_INVOICE_LINES_FETCH,
            GLOBAL_PROPERTIES.GENERAL_PAYABLE_AND_GENERAL_RECEIVABLE_ACCOUNTS,
            GLOBAL_PROPERTIES.INVOICE_LINE_EXTRA_DETAIL_PRODUCT_CATEGORIES,
            GLOBAL_PROPERTIES.QUICK_REGISTER_PATIENT_FORM_SECONDARY_IDENTIFIER_VALIDATIONS,
            GLOBAL_PROPERTIES.ENABLE_SEND_SMS_FOR_APPOINTMENT_CREATION,
            GLOBAL_PROPERTIES.ENABLE_SEND_SMS_FOR_APPOINTMENT_LIST,
            GLOBAL_PROPERTIES.ENABLE_SEND_SMS_FOR_RECALL_LIST,
            GLOBAL_PROPERTIES.ALLOW_CHANGE_PAYER_WITHOUT_METADATA_UPDATION,
            GLOBAL_PROPERTIES.ANALYTICS_CURRENCY_NUMERIC_SYSTEM,
            GLOBAL_PROPERTIES.QUEUE_APPROVAL_ENABLED,
            GLOBAL_PROPERTIES.QUEUE_REGISTRATION_ENABLED,
            GLOBAL_PROPERTIES.STORE_STUDY_DOCUMENTS_IN_LOCAL_SERVER,
            GLOBAL_PROPERTIES.STUDY_DOCUMENT_LOCAL_SERVER_BASE_URL,
            GLOBAL_PROPERTIES.DISABLE_INSURANCE_INVOICE_EDIT_V2,
            GLOBAL_PROPERTIES.USE_CHANGE_PAYER_SCREEN_PAYER_TYPES,
            GLOBAL_PROPERTIES.USE_REGISTRATION_V3,
            GLOBAL_PROPERTIES.SHOW_TAX_EXEMPT_FOR_CREDIT_BILL,
            GLOBAL_PROPERTIES.NPHIES_ENABLED,
            GLOBAL_PROPERTIES.IMPLEMENTATION_LOCATION,
            GLOBAL_PROPERTIES.DISABLE_APPROVAL_CONTAINER_V2,
            GLOBAL_PROPERTIES.START_DATE_FOR_HR_PAYROLL_PERIOD,
            GLOBAL_PROPERTIES.FORMULARY_ITEM_TYPE_OPTIONS,
            GLOBAL_PROPERTIES.EXPIRED_PRODUCT_INTERNAL_MOVE_LOCATIONS,
            GLOBAL_PROPERTIES.DUTY_ROSTER_PRINT_DATA,
            GLOBAL_PROPERTIES.COMPANY_EXPIRY_PRIVILEGE_CARDS,
            GLOBAL_PROPERTIES.CREDIT_MEMO_JASPER_PRINT,
            GLOBAL_PROPERTIES.HIDE_OTHER_PAYMENT_MODE_IN_MULTIMODE_PAYMENT,
            GLOBAL_PROPERTIES.POST_TYPE_PACS_CREDENTIALS_AND_ORDER_TYPES,
            GLOBAL_PROPERTIES.SEND_TO_BILLING_DISABLED_PRODUCT_ID,
            GLOBAL_PROPERTIES.DISABLE_NPHIES_CODE_VALIDATION,
            GLOBAL_PROPERTIES.DISABLE_SFDA_CODE_VALIDATION,
            GLOBAL_PROPERTIES.DISABLE_DATE_RANGE_LIMIT,
            GLOBAL_PROPERTIES.DISABLE_PROVIDERS_WITH_MINIMAL_DEPENDENT_VALUES,
            GLOBAL_PROPERTIES.SALES_DISCOUNT_CONFIG,
            GLOBAL_PROPERTIES.ENABLE_PREDETERMINATION,
            GLOBAL_PROPERTIES.ENABLE_PBM,
            GLOBAL_PROPERTIES.CDS_CUSTOM_KEY,
            GLOBAL_PROPERTIES.ENABLE_AUTO_ASSIGN_FOR_LAB_QUEUE,
            GLOBAL_PROPERTIES.ENABLE_USE_BATCH_PRICE,
            GLOBAL_PROPERTIES.ENABLE_SPLIT_INVOICES_FOR_REPORTABLE_ITEMS,
            GLOBAL_PROPERTIES.ENABLE_USER_ALERT_MESSAGE,
            GLOBAL_PROPERTIES.USER_ALERT_MESSAGE_CONTENT,
        ];
        const response2 =
            yield call(retriable().get, `${API.GLOBAL_PROPERTY.GET_PROPERTIES_WEB}${requiredGPs.join(',')}`);
        const response3 =
            yield call(retriable().get, getJsonPath('/qzConfig.json', COMMON_APPLICATION_CONFIG_URL));
        const response4 =
            yield call(retriable().get, `${API.GET_DYNAMIC_PROPERTY}${dynamicProperties.ROUNDING_METHODOLOGY}`);
        const response5 =
            yield call(retriable().get, API.GET_CLAIM_PREFIX);
        const response6 =
            yield call(retriable().get, API.PRODUCT_CATEGORY.CONS_CATEGS);
        persistRoundingMethodology(response4.data);
        const appConfiguration = {
            ...response.data,
            ...response3.data,
            claimPrefix: response5.data,
            consultationCategories:
                isArrayValidAndNotEmpty(response6.data) ? response6.data.map(aR => aR.key) : [],
        };
        if (isObjectValidAndNotEmpty(response2.data)) {
            Object.keys(response2.data).forEach((aKey) => {
                if (aKey === GLOBAL_PROPERTIES.ROUNDING_PLACES) {
                    persistRoundingPlaces(response2.data[aKey]);
                }
                if (aKey === GLOBAL_PROPERTIES.BILL_AMOUNT_ROUND) {
                    persistBillRoundPlaces(response2.data[aKey]);
                }
                if (aKey === GLOBAL_PROPERTIES.ANALYTICS_CURRENCY_NUMERIC_SYSTEM) {
                    persistAnalyticsCurrencyNumericSystem(response2.data[aKey]);
                }
                let value = response2.data[aKey];
                if ([GLOBAL_PROPERTIES.ALLOW_CONTRACT_EDIT_FOR_PAST_DATES].includes(aKey)) {
                    value = value === 'true';
                }
                appConfiguration[aKey.replace(/\./g, '_')] =
                    isJsonString(value) ? JSON.parse(value) : value;
            });
        }
        yield put({
            type: FETCH_APP_CONFIG_SUCCESS,
            appConfiguration,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        console.error('error in api call', e);
        yield put({ type: FETCH_APP_CONFIG_FAIL });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export default fetchAppConfigWorkerSaga;
